import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const Impressum = () => (
  <Layout>
    <SEO title="Home" />
    <table>
      <tr><td>Medieninhaber</td><td>Dipl.-Ing. Dr. Walter Brunner</td></tr>
      <tr><td>Anschrift</td><td>Pöttmesserweg 4<br/>4400 Garsten/Steyr</td></tr>
      <tr><td>email</td><td><a href="mailto:office@zi-brunner.at">office@zi-brunner.at</a></td></tr>
      <tr><td>Telefon</td><td>07252 41870</td></tr>
      <tr><td>Gerichtsstand</td><td>Steyr</td></tr>
      <tr><td>UID</td><td>ATU38224603</td></tr>
      <tr><td>Kammer</td><td>Mitglied der Kammer der Architekten und Ingenieurkonsulenten für Oberösterreich und Salzburg</td></tr>
      <tr><td>Sitz des Medieninhabers</td><td>Garsten</td></tr>
      <tr><td>Tätigkeit</td><td>Ziviltechnikerberuf auf dem Fachgebiet Bauingenieurwesen</td></tr>
      
    </table>
  </Layout>
)

export default Impressum
